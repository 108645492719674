<!--操作票管理-->
<template>
    <div class="ui-layout-container ui-height-100">
        <div v-if="showVisible" class="ui-layout-container ui-layout-flex ui-layout-flex--column ui-height-100">
            <div class="workflow-list__header">
                <div class="workflow-list__navbar">
                    <el-menu :default-active="type" class="el-menu-bm" mode="horizontal" @select="handleSelect">
                        <el-menu-item v-if="unsanctionedShow" index="unsanctioned">未批准定值</el-menu-item>
                        <el-menu-item v-if="executoryShow" index="executory">执行中定值</el-menu-item>
                        <el-menu-item v-if="rescissoryShow" index="rescissory">已废除定值</el-menu-item>
                    </el-menu>
                </div>
            </div>
            <div class="ui-layout-container ui-layout-flex ui-layout-flex--column ui-height-100">
                <div v-if="canAdd" class="ui-layout-content--full ui-form-actions" style="margin: 20px 20px 0px 20px">
                    <el-button @click="addOrUpdateHandle('add')" type="primary" style="border-radius: 4px"
                        >+ 新建</el-button
                    >
                </div>
                <div class="ui-layout-content--full ui-layout-flex--1" style="margin: 0px 20px 20px 20px">
                    <ex-search-table-pagination
                        style="height: 100%"
                        ref="searchTable"
                        :fetch="fetchData"
                        :columns="columns"
                        :formOptions="formOptions"
                        :searchWhenSortChange="true"
                        pageSizeKey="pageSize"
                        pageIndexKey="pageNumber"
                        listField="items"
                        totalField="totalItemCount"
                        :params="{definitionCode}"
                        :paramsMethod="paramsMethod"
                    >
                        <template slot="append">
                            <el-table-column align="center" label="操作" type="action" width="240" fixed="right">
                                <template slot-scope="{row}">
                                    <el-button @click="lookInfo(row)">查看</el-button>
                                    <el-button v-if="getButton(row)" @click="conductInfo(row)">处理</el-button>
                                    <el-button v-if="getDelButton(row)" @click="deleteInfo(row)">删除</el-button>
                                    <el-button v-if="row.nextNode === null && userCode" @click="abolishInfo(row)"
                                        >废除</el-button
                                    >
                                </template>
                            </el-table-column>
                        </template>
                    </ex-search-table-pagination>
                </div>
            </div>
        </div>
        <Form v-if="formVisible" ref="issuedTaskForm" @closeForm="closeForm"></Form>
        <Details v-if="detailsVisible" ref="issuedTaskDetails" @closeForm="closeForm"></Details>
        <ConstValueTable v-if="constValueTableVisible" ref="constValueTable" @closeForm="closeForm"></ConstValueTable>
    </div>
</template>

<script>
import Form from './constantValueForm';
import Details from './constantValueDetail';
import ConstValueTable from './constanValueTable'
import * as dayjs from 'dayjs';
export default {
    data() {
        return {
            state: 'pending',
            showVisible: true,
            formVisible: false,
            detailsVisible: false,
            canAdd: false, //是否有权限新增
            type: false,
            key: '',
            items: [],
            definitionCode: 'elec_constant_value_business',
            extraProp: {},
            userCode: '',
            unsanctionedShow: false, //未批准定值
            executoryShow: false, //执行中定值
            rescissoryShow: false, //已废除定值
            constValueTableVisible:false
        };
    },
    components: {
        Form,
        Details,
        ConstValueTable
    },
    computed: {
        columns() {
            return [
                {
                    prop: 'billName',
                    label: '整定单名称',
                    formatter: (row, column, cellValue) => {
                        let data = '';
                        if (row.currentRecord && row.currentRecord.data.billName) {
                            data = row.currentRecord.data.billName;
                        }
                        return data;
                    },
                },
                {
                    prop: 'adjustBy',
                    label: '整定人',
                    formatter: (row, column, cellValue) => {
                        let data = '';
                        if (row.currentRecord && row.currentRecord.data.adjustBy) {
                            data = row.currentRecord.data.adjustBy;
                        }
                        return data;
                    },
                },
                {
                    prop: 'adjustUnit',
                    label: '整定单位',
                    formatter: (row, column, cellValue) => {
                        let data = '';
                        if (row.currentRecord && row.currentRecord.data.adjustUnit) {
                            data = row.currentRecord.data.adjustUnit;
                        }
                        return data;
                    },
                },
                {
                    prop: 'startTime',
                    label: '提交时间',
                    formatter: (row, column, cellValue) => {
                        if (row) {
                            return dayjs.utc(row.startTime).add(8, 'hours').format('YYYY-MM-DD HH:mm');
                        }
                    },
                },
                {
                    prop: 'endTime',
                    label: '状态',
                    formatter: (row, column, cellValue) => {
                        if (this.key === 'rescissory') {
                            return '已废除';
                        }
                        if (row.nextNode) {
                            return row.nextNode.name;
                        }
                        return '执行中';
                    },
                },
            ];
        },
        formOptions() {
            let forms = [];
            const key = this.key ? this.key : this.type;
            if (key === 'unsanctioned') {
                forms.push(
                    {
                        prop: 'state',
                        itemType: 'select',
                        clearable: true,
                        filterable: true,
                        placeholder: '请选择业务状态',
                        defaultValue: 'all',
                        options: [
                            {code: 'all', value: '全部'},
                            {code: 'processed', value: '已处理'},
                            {code: 'pending', value: '待处理'},
                            {code: 'selfStart', value: '我发起的'},
                        ],
                        valueKey: 'code',
                        labelKey: 'value',
                    },
                    {
                        prop: 'billName',
                        itemType: 'input',
                        clearable: true,
                        placeholder: '整定单名称',
                    },
                    {
                        prop: 'billNumber',
                        itemType: 'input',
                        clearable: true,
                        placeholder: '整定单编号',
                    }
                );
            }
            if (key === 'executory') {
                forms.push(
                    {
                        prop: 'state',
                        itemType: 'select',
                        clearable: true,
                        filterable: true,
                        placeholder: '请选择业务状态',
                        defaultValue: 'all',
                        options: [
                            {code: 'all', value: '全部'},
                            {code: 'processed', value: '已处理'},
                            {code: 'pending', value: '待处理'},
                            {code: 'selfStart', value: '我发起的'},
                        ],
                        valueKey: 'code',
                        labelKey: 'value',
                    },
                    {
                        prop: 'billName',
                        itemType: 'input',
                        clearable: true,
                        placeholder: '整定单名称',
                    }
                );
            }
            if (key === 'rescissory') {
                forms.push({
                    prop: 'billName',
                    itemType: 'input',
                    clearable: true,
                    placeholder: '整定单名称',
                });
            }
            return {forms};
        },
    },
    methods: {
        getDelButton(row) {
            let username = this.$store.state.userInfo.username;
            let createUser = row.extraProps.filter((item) => item.code === 'createUser');
            if (
                createUser &&
                createUser.length > 0 &&
                row.curNode?.code === 'elec_constant_value_business_start' &&
                createUser[0].value === username
            ) {
                return true;
            }
            return false;
        },
        getButton(row) {
            let userCode = this.$store.state.userInfo.roleCodeList;
            let res = false;
            if (
                row.nextNode &&
                userCode.indexOf('DZYWFQR') > -1 &&
                row.nextNode.code === 'elec_constant_value_business_start'
            ) {
                res = true;
            }
            if (
                row.nextNode &&
                userCode.indexOf('DZYWSHR') > -1 &&
                row.nextNode.code === 'elec_constant_value_business_audit'
            ) {
                res = true;
            }
            if (
                row.nextNode &&
                userCode.indexOf('DZYWPZR') > -1 &&
                row.nextNode.code === 'elec_constant_value_business_ratify'
            ) {
                res = true;
            }
            if (
                row.nextNode &&
                userCode.indexOf('DZYWJSR') > -1 &&
                row.nextNode.code === 'elec_constant_value_business_receipt'
            ) {
                res = true;
            }
            return res;
        },
        getUserCode() {
            let user = this.$store.state.userInfo;
            if (user && user.roleCodeList) {
                let code = user.roleCodeList.filter((e) => e === 'DZYWPZR' || e === 'DZYWSHR')[0];
                if (code) {
                    this.userCode = true;
                }
            }
        },
        getMenu() {
            let code = this.$store.state.userInfo.roleCodeList;
            code.forEach((item) => {
                if (item === 'DZYWFQR') {
                    this.unsanctionedShow = true;
                    this.executoryShow = false;
                    this.rescissoryShow = false;
                }
                if (item === 'DZYWSHR' || item === 'DZYWPZR') {
                    this.unsanctionedShow = true;
                    this.executoryShow = true;
                    this.rescissoryShow = true;
                }
                if (item === 'DZYWJSR') {
                    this.unsanctionedShow = false;
                    this.executoryShow = true;
                    this.rescissoryShow = false;
                }
            });
        },
        getType() {
            let code = this.$store.state.userInfo.roleCodeList;
            code.forEach((item) => {
                if (item === 'DZYWFQR' || item === 'DZYWPZR' || item === 'DZYWSHR') {
                    this.type = 'unsanctioned';
                }
                if (item === 'DZYWJSR') {
                    this.type = 'executory';
                }
            });
        },
        paramsMethod(params) {
            params.type = params.state;
            params['extraProp.billName'] = params.billName;
            params['extraProp.billNumber'] = params.billNumber;
            delete params.billName;
            delete params.billNumber;
            delete params.state;
        },
        deleteInfo(row) {
            let ids = [];
            ids.push(row.id);
            this.$confirm(this.$t('确定要删除这条数据？'), this.$t('提示'), {
                confirmButtonText: this.$t('确认'),
                cancelButtonText: this.$t('取消'),
                type: 'warning',
            }).then(() => {
                this.$client.workflowDelete(ids).then((res) => {
                    if (res.code === 0) {
                        this.$message({
                            message: '删除成功',
                            type: 'success',
                        });
                        this.$refs.searchTable.searchHandler();
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            });
        },
        abolishInfo(row) {
            const reviewForm = {
                reviewNote: '',
                reviewStatus: 0,
                reviewParam: 0,
                reviewBy: '',
            };
            let nextRoles = [];
            let userRoleIds = this.$store.state.userInfo.roleCodeList;
            if (this.roleList) {
                //设置下环节
                userRoleIds.forEach((temp) => {
                    this.roleCodeList.forEach((item) => {
                        if (temp === 'DZYWFQR' && item.roleCode === 'DZYWSHR') {
                            nextRoles.push(item.roleId);
                        }
                        if (temp === 'DZYWSHR' && item.roleCode === 'DZYWPZR') {
                            nextRoles.push(item.roleId);
                        }
                        if (temp === 'DZYWPZR' && item.roleCode === 'DZYWJSR') {
                            nextRoles.push(item.roleId);
                        }
                    });
                });
            }
            //封装表单数据
            const data = Object.assign({}, row.currentRecord.data, reviewForm);
            this.$confirm('确认废除？', '提示', {
                confirmButtonClass: 'el-button el-button--mini el-button--primary',
                type: 'warning',
            }).then(() => {
                this.$client
                    .workflowInstanceProcess({
                        definitionCode: 'elec_constant_value_business',
                        instanceId: row.curNode.id,
                        nodeCode: row.nextNode ? row.nextNode.code : row.curNode.code,
                        operationType: 'save',
                        data,
                        user: this.$store.state.userInfo.username,
                        roles: this.$store.state.userRole,
                        nextRoles,
                        extraProp: {
                            isRatify: true,
                            isDelete: true,
                        },
                    })
                    .then((resp) => {
                        if (resp.code === 0) {
                            this.$message({
                                message: '删除成功',
                                type: 'success',
                            });
                            this.$refs.searchTable.searchHandler();
                        }
                    });
            });
        },
        handleSelect(key) {
            this.key = key;
            this.extraProp = {};
            switch (key) {
                case 'unsanctioned':
                    this.extraProp.isRatify = false;
                    this.extraProp.isDelete = false;
                    break;
                case 'executory':
                    this.extraProp.isRatify = true;
                    this.extraProp.isDelete = false;
                    break;
                case 'rescissory':
                    this.extraProp.isDelete = true;
                    this.extraProp.isRatify = true;
                    break;
                default:
                    break;
            }
            this.$nextTick(() => {
                this.$refs.searchTable.searchHandler();
            });
        },
        async selectKey(key) {
            this.extraProp = {};
            switch (key) {
                case 'unsanctioned':
                    this.extraProp.isRatify = false;
                    this.extraProp.isDelete = false;
                    break;
                case 'executory':
                    this.extraProp.isDelete = false;
                    this.extraProp.isRatify = true;
                    break;
                case 'rescissory':
                    this.extraProp.isDelete = true;
                    this.extraProp.isRatify = true;
                    break;
                default:
                    break;
            }
        },
        //是否有权限新增
        isCanAdd() {
            let user = this.$store.state.userInfo;
            if (user && user.roleCodeList && user.roleCodeList.indexOf('DZYWFQR') > -1) {
                this.canAdd = true;
            }
        },
        //处理
        conductInfo(item, type) {
            this.showVisible = false;
            this.formVisible = true;
            this.$nextTick(() => {
                this.$refs.issuedTaskForm.init(item);
            });
        },
        //查看
        lookInfo(row) {
            this.showVisible = false;
            if( row.curNode.code === 'elec_constant_value_business_receipt'){
                this.constValueTableVisible = true
                this.$nextTick(() => {
                    this.$refs.constValueTable.init(row);
                });
            }else{
                this.detailsVisible = true;
                this.$nextTick(() => {
                    this.$refs.issuedTaskDetails.init(row);
                });
            }
           
        },
        //关闭页面
        closeForm(type) {
            this.showVisible = true;
            this.detailsVisible = false;
            this.formVisible = false;
            this.constValueTableVisible = false;
        },
        // 新增
        addOrUpdateHandle(type) {
            this.showVisible = false;
            this.formVisible = true;
            this.$nextTick(() => {
                this.$client
                    .workflowInstanceProcess({
                        definitionCode: 'elec_constant_value_business',
                        user: this.$store.state.userInfo.username,
                        roles: this.$store.state.userRole,
                    })
                    .then((resp) => {
                        this.$refs.issuedTaskForm.init({
                            id: resp.data.instance.id,
                            nextNode: resp.data.nextNode,
                            definitionCode: 'elec_constant_value_business',
                            type,
                        });
                    });
            });
        },
        async fetchData(params) {
            this.state = params.type;
            await this.selectKey(this.key || this.type);
            //this.handleSelect(this.type);
            if (!params.extraProp) {
                for (let item in this.extraProp) {
                    params[`extraProp.${item}`] = this.extraProp[item];
                }
            }
            let roleCodeList = this.$store.state.userInfo.roleCodeList;
            if (this.key === 'executory') {
                roleCodeList.forEach((item) => {
                    if (item === 'DZYWSHR' || item === 'DZYWPZR') {
                        params.type = 'processed';
                    }
                });
            }
            //params.Type = 'pending';
            return new Promise((resolve, reject) => {
                this.$client.workflowInstanceQuery(params).then((data) => {
                    let ids = [];
                    let result = data.data.items.map((e) => {
                        e.currentRecordId = e.records.sort((a, b) => (b.startTime > a.startTime ? 1 : -1))[0].id;
                        e.records.forEach((r) => {
                            ids.push(r.id);
                        });
                        return e;
                    });
                    this.$client.getQueryRecords({ids}).then((resp) => {
                        result = result.map((e) => {
                            resp.data.items.sort();
                            e.currentRecord = resp.data.items.filter((i) => i.id === e.currentRecordId)[0];
                            e.records.map((i) => {
                                const record = resp.data.items.filter((r) => r.id === i.id)[0];
                                i.data = record ? record.data : null;
                                return i;
                            });
                            e.currentStatus = params.type;
                            return e;
                        });
                        let tableData = {...data.data};
                        tableData.items = result;
                        resolve({data: tableData});
                    });
                });
            });
        },
        isEdit(row) {
            let userCode = this.$store.state.userInfo.roleCodeList;
            if (
                row.nextNode &&
                userCode.indexOf('DZYWFQR') > -1 &&
                row.nextNode.code === 'elec_constant_value_business_start'
            ) {
                return true;
            }
            if (
                row.nextNode &&
                userCode.indexOf('DZYWSHR') > -1 &&
                row.nextNode.code === 'elec_constant_value_business_audit'
            ) {
                return true;
            }
            if (
                row.nextNode &&
                userCode.indexOf('DZYWPZR') > -1 &&
                row.nextNode.code === 'elec_constant_value_business_ratify'
            ) {
                return true;
            }
            if (
                row.nextNode &&
                userCode.indexOf('DZYWJSR') > -1 &&
                row.nextNode.code === 'elec_constant_value_business_receipt'
            ) {
                return true;
            }
            return false;
        },
    },
    created() {
        this.isCanAdd();
        this.getType();
        this.getMenu();
        this.getUserCode();
    },
};
</script>

<style lang="less" scoped>
@import '~@/assets/style/variables.less';

.workflow-list {
    &__navbar {
        border-bottom: 1px solid @bg-border;
        padding-top: 10px;
        /deep/ .el-menu {
            background-color: transparent;
        }
        /deep/ .el-menu.el-menu--horizontal {
            height: 30px;
            border: none;
            margin-right: 0;
            margin-bottom: 0;
        }
        /deep/ .el-menu--horizontal > .el-menu-item {
            height: 35px;
            line-height: 35px;
        }
        /deep/ .el-header {
            padding: 0;
        }
        /deep/ .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
        /deep/ .el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
        /deep/ .el-menu--horizontal > .el-submenu .el-submenu__title:hover {
            background-color: lighten(@bg-dark, 22%);
        }
        /deep/ .el-menu--horizontal > .el-menu-item.is-active {
            border-color: #ededfd;
        }
    }
}
</style>
