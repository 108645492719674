<template>
    <div style="padding: 30px 200px 30px 200px">
        <el-card>
            <h1 style="text-align: center">继点保护定值通知单</h1>
            <h4 style="text-align: right">共 {{ countPage }} 页 , 第 {{ indexPage }} 页</h4>
            <table border="1" style="width: 100%; text-align: center">
                <tr>
                    <td style="width: 10%">定制单编号</td>
                    <td colspan="5">{{ dataForm.billNumber }}</td>
                </tr>
                <tr>
                    <td>厂站名称</td>
                    <td style="width: 40%">{{ dataForm.factoryName }}</td>
                    <td style="width: 10%">设备名称</td>
                    <td colspan="2">{{ dataForm.equipmentName }}</td>
                </tr>
                <tr>
                    <td>保护型号</td>
                    <td>{{ dataForm.model }}</td>
                    <td>开关编号</td>
                    <td colspan="2">{{ dataForm.switchNumber }}</td>
                </tr>
                <tr>
                    <td>CT变化</td>
                    <td>相：{{ dataForm.ctPhaseChange }} 零：{{ dataForm.ctZeroChange }}</td>
                    <td>PT变化</td>
                    <td colspan="2">{{ dataForm.ptVoltageRatio }}</td>
                </tr>
                <!-- <tr>
                    <td colspan="4">
                        <el-table
                            :border="true"
                            :data="dataForm.constantValueList"
                            :default-sort="{prop: 'type', order: 'ascending'}"
                        >
                            <el-table-column fixed type="index" label="序号" width="150%"> </el-table-column>
                            <el-table-column prop="type" label="定值单类型" width="150%"> </el-table-column>
                            <el-table-column prop="name" label="定制单名称" width="450%"> </el-table-column>
                            <el-table-column prop="oldValue" label="原定值" width="250%"> </el-table-column>
                            <el-table-column prop="newValue" label="新定值" width="250%"> </el-table-column>
                            <el-table-column prop="remark" label="备注" width="180%"> </el-table-column>
                        </el-table>
                    </td>
                </tr> -->
            </table>
            <table border="1" style="width: 100%; text-align: center">
                <tr>
                    <th>序号</th>
                    <th>定值单类型</th>
                    <th>定制单名称</th>
                    <th>原定值</th>
                    <th>新定值</th>
                    <th>备注</th>
                </tr>
                <tr v-for="(item, index) in dataForm.constantValueList" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td>{{ item.type }}</td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.oldValue }}</td>
                    <td>{{ item.newValue }}</td>
                    <td>{{ item.remark }}</td>
                </tr>
                <tr>
                    <td>备注</td>
                    <td colspan="5">{{ dataForm.remark }}</td>
                </tr>
            </table>
            <table border="1" style="width: 100%; text-align: center">
                <tr>
                    <td>批准人</td>
                    <td>审核人</td>
                    <td>整定单位</td>
                    <td>通知时间</td>
                    <td colspan="2">执行单位</td>
                    <td>执行时间</td>
                </tr>
                <tr>
                    <td rowspan="3">{{ dataForm.approver }}</td>
                    <td rowspan="3">{{ dataForm.auditor }}</td>
                    <td>{{ dataForm.adjustUnit }}</td>
                    <td rowspan="3">{{ dataForm.notificationTime }}</td>
                    <td>{{ dataForm.executableUnit }}</td>
                    <td>{{ dataForm.executableUnitTwo }}</td>
                    <td rowspan="3">{{ dataForm.executeTime }}</td>
                </tr>
                <tr>
                    <td>整定人</td>
                    <td colspan="2">执行人</td>
                </tr>
                <tr>
                    <td>{{ dataForm.adjustBy }}</td>
                    <td style="width: 10%">{{ dataForm.executeBy }}</td>
                    <td>{{ dataForm.executeByTwo }}</td>
                </tr>
                <tr>
                    <td>更改原因</td>
                    <td colspan="2">{{ dataForm.changeReason }}</td>
                    <td>执行情况</td>
                    <td colspan="3">{{ dataForm.executiveCondition }}</td>
                </tr>
            </table>
        </el-card>
        <div style="text-align: center; margin-top: 20px">
            <el-button @click="closeForm">关闭</el-button>
            <el-button @click="dataExport()">导出</el-button>
        </div>
    </div>
</template>
<script>
import {previewPdf, fileDownload} from '@/utils';
import {cloneDeep} from 'lodash';
export default {
    name: 'constValueTable',
    data() {
        return {
            countPage: 1,
            indexPage: 1,
            dataForm: {},
        };
    },
    methods: {
        async init(item) {
            if (item && item.currentRecordId) {
                let ids = [];
                ids.push(item.currentRecordId);
                await this.$client.getQueryRecords({ids}).then((resp) => {
                    this.dataForm = resp.data.items[0].data;
                    this.dataHandler(this.dataForm.constantValueList);
                    let data = this.unitList?.filter((e) => e.name === this.dataForm.jobOrder.jobUnit)[0];
                    if (data) {
                        this.jobUnitId = data.id;
                    }
                });
            }
        },
        closeForm() {
            this.$emit('closeForm');
        },
        dataHandler(data) {
            if (data) {
                let conut = 25;
                let num = conut - data.length;
                if (num > 0) {
                    for (let i = 0; i < num; i++)
                        data.push({
                            type: '',
                            name: '',
                            oldValue: '',
                            newValue: '',
                            remark: '',
                        });
                }
                data.sort(function (a, b) {
                    return a.type < b.type ? 1 : -1;
                });
            }
        },
        constantValuehandler(data) {
            let map = new Map();
            data.foreach((item) => {
                if (!map.get(item.type)) {
                    map.push(item.type, new Array());
                }
                map.get(item.type).add(item);
            });
            return map;
        },
        dataExport() {
            // let constantFormList = new Array();
            // let constantValueList = cloneDeep(this.dataForm.constantValueList);
            // let totalPage = Math.ceil(this.dataForm.constantValueList.length / 25);
            // for (let i = 1; i < totalPage; i++) {
            //     debugger
            //     let data = cloneDeep(this.dataForm);
            //     let constantValues = new Array();
            //     for (let k = totalPage * i; k <= totalPage * 25; i++) {
            //         constantValues[k] = constantValueList[k];
            //     }
            //     data.constantValueList = constantValues;
            //     constantFormList.push(data);
            // }
            fileDownload('/business/constantValue/dataExportInfo', {method: 'POST', data: this.dataForm});
        },
    },
};
</script>
<style lang="less" scoped>
.remove_table {
    border-bottom: 0px;
    border-top: 0px;
}
</style>