<template>
    <div style="padding: 30px 200px 30px 200px">
        <div >
            <el-form ref="dataForm" :model="dataForm" :rules="dataRule"
                     label-width="120px" label-position="top" :disabled="isDisabledForm">
                <!--class="card-pub-notice card-ext-center-title"-->
                <el-card >
                    <div style="text-align: center">
                <span style="font-size: 18px">
                    <el-form-item prop="billName">
                    <input
                        
                        class="factory_name"
                        v-model="dataForm.billName"
                    />
                    </el-form-item>
                    <div style="float: right">
                    <!-- <el-button @click="dataExport()">导出</el-button> -->
                    </div>
                </span>
                    </div>
                    <el-card>
                <el-row :gutter="24">
                    <el-col :span="6">
                        <el-form-item label="定值单编号:" prop="billNumber">
                            <el-input  v-model="dataForm.billNumber"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="厂站名称:" prop="factoryName">
                            <el-input  v-model="dataForm.factoryName"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="设备名称:" prop="equipmentName">
                            <el-input  v-model="dataForm.equipmentName"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="保护型号:" prop="model">
                            <el-input  v-model="dataForm.model"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="24">
                    <el-col :span="6">
                        <el-form-item label="开关编号:" prop="switchNumber">
                            <el-input  v-model="dataForm.switchNumber"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="CT变比(相):" prop="ctPhaseChange">
                            <el-input  v-model="dataForm.ctPhaseChange"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="CT变比(零):" prop="ctZeroChange">
                            <el-input  v-model="dataForm.ctZeroChange"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="PT变比:" prop="ptVoltageRatio">
                            <el-input  v-model="dataForm.ptVoltageRatio"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                 <el-table
                     :data="dataForm.constantValueList"
                     border
                     style="width: 100%">
                     <el-table-column
                         fixed
                         type="index"
                         label="序号"
                         width="50">
                     </el-table-column>
                     <el-table-column
                         fixed
                         prop="type"
                         label="定值单类型">
                         <template slot-scope="scope">
                             <el-select  v-model="scope.row.type">
                                 <el-option
                                     v-for="item in constantValueTypeList"
                                     :label="item.name"
                                     :key="item.name"
                                     :value="item.value"

                                 >
                                 </el-option>
                             </el-select>
                         </template>
                     </el-table-column>
                     <el-table-column
                         fixed
                         prop="name"
                         label="定值单名称">
                         <template slot-scope="scope">
                             <el-input  v-model="scope.row.name"></el-input>
                         </template>
                     </el-table-column>
                     <el-table-column
                         fixed
                         prop="oldValue"
                         label="原定值">
                         <template slot-scope="scope">
                             <el-input  v-model="scope.row.oldValue"></el-input>
                         </template>
                     </el-table-column>
                     <el-table-column
                         fixed
                         prop="newValue"
                         label="新定值">
                         <template slot-scope="scope">
                             <el-input  v-model="scope.row.newValue"></el-input>
                         </template>
                     </el-table-column>
                     <el-table-column
                         fixed
                         prop="remark"
                         label="备注">
                         <template slot-scope="scope">
                             <el-input  v-model="scope.row.remark"></el-input>
                         </template>
                     </el-table-column>
                     <el-table-column
                         width="50"
                         fixed
                         label="操作">
                         <template slot-scope="scope">
                             <div style="text-align: center">
                             <i class="el-icon-remove"  title="" @click="delTableData(scope.$index)"></i>
                             </div>
                         </template>
                     </el-table-column>
                 </el-table>
                    <div style="text-align: center">
                    <i  class="el-icon-circle-plus" title="" @click="addTableData"></i>
                    </div>
                    <el-form-item label="备注:">
                        <el-input  v-model="dataForm.remark" type="textarea" :rows="3"></el-input>
                    </el-form-item>
                    </el-card>
                    <el-card>
                    <el-row :gutter="24">
                        <el-col :span="12">
                            <el-form-item label="更改原因" prop="changeReason">
                                <el-input  v-model="dataForm.changeReason"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="整定单位" prop="adjustUnit">
                                <el-input v-model="dataForm.adjustUnit" ></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="整定人" prop="adjustBy">
                                <el-input v-model="dataForm.adjustBy" ></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="批准人" prop="approver">
                            <el-input v-model="dataForm.approver" disabled></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="审核人" prop="auditor">
                                <el-input v-model="dataForm.auditor" disabled></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="通知时间" prop="notificationTime">
                                 <el-date-picker
                                 :disabled="workFlowCode !== 'elec_constant_value_business_audit'"
                                    placeholder="请选择通知时间"
                                    v-model="dataForm.notificationTime"
                                    style="width: 100%"
                                    type="datetime"
                                    format="yyyy-MM-dd"
                                    value-format="yyyy-MM-dd">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6" v-if="false">
                            <el-form-item label="执行单位" prop="executableUnit">
                                <el-input v-model="dataForm.executableUnit" disabled></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12" v-if="false">
                            <el-form-item label="执行情况" prop="executiveCondition">
                                <el-input  v-model="dataForm.executiveCondition"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6" v-if="false">
                            <el-form-item label="执行人" prop="executeBy">
                                <el-input v-model="dataForm.executeBy" disabled></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6" v-if="false">
                            <el-form-item label="执行时间" prop="executeTime">
                                <el-date-picker
                                    placeholder="请选择执行时间"
                                    v-model="dataForm.executeTime"
                                    style="width: 100%"
                                    type="datetime"
                                    format="yyyy-MM-dd HH:mm"
                                    value-format="yyyy-MM-dd HH:mm">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    </el-card>
                </el-card>
            </el-form>
        </div>
        <div style="text-align: center; margin-top: 20px">
            <el-button v-if="!this.start" type="primary" @click="saveApply()">提交</el-button>
            <el-button v-if="!this.audit" type="primary" @click="saveApply()">审核通过</el-button>
            <el-button v-if="!this.ratify" type="primary" @click="saveApply()">批准通过</el-button>
            <el-button v-if="!this.receipt" type="primary" @click="saveApply()">执行完成</el-button>
            <el-button v-if="this.start" @click="showRejectDialog" type="primary">驳回</el-button>
            <el-button @click="closeForm">关闭</el-button>
        </div>

        <el-dialog title="选择驳回步骤" :visible.sync="dialogVisible" width="30%">
            <el-table
                :data="rejectNodesList"
                border
                highlight-current-row
                @current-change="handleCurrentChange"
                style="width: 100%;"
                height="250"
            >
                <el-table-column align="center" fixed prop="name" :show-overflow-tooltip="true" label="步骤名称">
                </el-table-column>
            </el-table>
            <div style="text-align:center;margin-top:10px;">
                <el-button type="primary" @click="rejectSubmit">驳回</el-button>
            </div>
        </el-dialog>
        <record-history v-if="showRecordHistory" :instance="data"/>

    </div>
</template>
<script>
import * as dayjs from 'dayjs';
import {previewPdf, fileDownload} from '@/utils';

export default {
    data() {
        return {
            reviewForm: {
                reviewNote: '',
                reviewStatus: 0,
                reviewParam: 0,
                reviewBy: ''
            },
            constantValueTypeList: [{name: '控制字',value:"control"}, {name: '整定值',value:"setting"},{name: '无',value:"null"}],
            currentRow: null,
            data: {},
            showRecordHistory: false,
            rejectNodesList: [],
            dialogVisible: false,
            visible: true,
            roleList: [],
            roleCodeList: [],
            tableData: [],
            roleInfo: [], //用户信息
            userName: '', //当前登录用户账号
            nodeCode: '', // 下一步流程ID
            instanceId: '', //当前流程ID
            jumpNodeId: '', //上一步流程ID
            jobUnitId: '', //运维单位id
            definitionCode: '', //流程id
            workFlowCode: '', //流程code
            start: false, //开始流程
            audit: false, //审核流程
            ratify: false, //批准
            receipt: false, //接收
            isRatify: false, //是否批准
            dataForm: {
                billName: '', //定值单名称
                billNumber: '', //定值单编号
                factoryName: '', //厂站名称
                equipmentName: '', //设备名称
                model: '', //型号
                switchNumber: '', //开关编号
                ctPhaseChange: '', //CT变比（相）
                ctZeroChange: '', //CT变比(零)
                ptVoltageRatio: '', //PT变比
                constantValueList: [], //定值单
                remark: '', //备注
                approver: '', //批准人
                auditor: '', //审核人
                adjustUnit: '', //整定单位
                adjustBy: '', //整定人
                notificationTime: '', //通知时间
                executableUnit: '', //执行单位
                executeBy: '', //执行人
                executeTime: '', //执行时间
                changeReason: '', //更改原因
                executiveCondition: '', //执行情况
            },
            dataRule: {
                billName: [
                    {required: true, message: '定值单名称必填,不能为空', trigger: 'blur'},
                ], //定值单名称
                billNumber: [
                    {required: false, message: '定值单编号必填,不能为空', trigger: 'blur'},
                ], //定值单编号
                factoryName: [
                    {required: false, message: '厂站名称必填,不能为空', trigger: 'blur'},
                ], //厂站名称
                equipmentName: [
                    {required: true, message: '设备名称必填,不能为空', trigger: 'blur'},
                ], //设备名称
                model: [
                    {required: true, message: '型号必填,不能为空', trigger: 'blur'},
                ], //型号
                switchNumber: [
                    {required: true, message: '开关编号必填,不能为空', trigger: 'blur'},
                ], //开关编号
                ctPhaseChange: [
                    {required: true, message: 'CT变比（相）必填,不能为空', trigger: 'blur'},
                ], //CT变比（相）
                ctZeroChange: [
                    {required: false, message: 'CT变比(零)必填,不能为空', trigger: 'blur'},
                ], //CT变比(零)
                ptVoltageRatio: [
                    {required: true, message: 'PT变比必填,不能为空', trigger: 'blur'},
                ], //PT变比
                constantValueList: [
                    {required: true, message: '定值单必填,不能为空', trigger: 'blur'},
                ], //定值单
                remark: [
                    {required: true, message: '备注必填,不能为空', trigger: 'blur'},
                ], //备注
                changeReason: [
                    {required: true, message: '更改原因必填,不能为空', trigger: 'blur'},
                ], //更改原因
                executiveCondition: [
                    {required: false, message: '执行情况必填,不能为空', trigger: 'blur'},
                ], //执行情况
            },
            isDisabledForm:false
        };
    },
    methods: {
        //删除定值单
        delTableData(index) {
            this.dataForm.constantValueList.splice(index, 1);
        },
        //添加定值单
        addTableData() {
            let s = {
                type: '',
                name: '',
                oldValue: '',
                newValue: '',
                remark: '',
            };
            this.dataForm.constantValueList.push(s);
        },
        showRejectDialog() {
            this.dialogVisible = true;
        },
        rejectSubmit() {
            const data = Object.assign({}, this.dataForm, this.reviewForm);
            if (this.currentRow.code === 'elec_constant_value_business_start' || this.currentRow.code === 'elec_constant_value_business_audit') {
                this.isRatify = false;
            }
            if (this.currentRow.code === 'elec_constant_value_business_ratify' || this.currentRow.code === 'elec_constant_value_business_receipt') {
                this.isRatify = true;
            }
            if (this.currentRow == null) {
                this.$message({
                    message: '请选择驳回步骤',
                    type: 'error'
                });
                return;
            }
            this.$confirm(`确定驳回到${this.currentRow.name}?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$client
                    .workflowInstanceProcess({
                        definitionCode: 'elec_constant_value_business',
                        instanceId: this.data.id,
                        nodeCode: this.data.nextNode.code,
                        operationType: 'reject',
                        rejectNodeCode: this.currentRow.code,
                        data,
                        user: this.$store.state.userInfo.username,
                        roles: this.$store.state.userRole,
                        nextRoles: this.getRejectNextRoles(this.currentRow.code),
                        extraProp: {
                            isRatify: this.isRatify,
                            isDelete: false,
                        },
                    })
                    .then(() => {
                        this.$message({
                            message: '操作成功',
                            type: 'success'
                        });
                        this.closeForm(2);
                    });
            });
        },
        getRejectNextRoles(code) {
            let roleIds = [];
            if (code === 'elec_constant_value_business_start') {
                this.roleCodeList.forEach(item => {
                    if (item.roleCode === 'DZYWFQR') {
                        roleIds.push(item.roleId);
                    }
                });
            }
            if (code === 'elec_constant_value_business_audit') {
                this.roleCodeList.forEach(item => {
                    if (item.roleCode === 'DZYWSHR') {
                        roleIds.push(item.roleId);
                    }
                });
            }
            if (code === 'elec_constant_value_business_ratify') {
                this.roleCodeList.forEach(item => {
                    if (item.roleCode === 'DZYWPZR') {
                        roleIds.push(item.roleId);
                    }
                });
            }
            if (code === 'elec_constant_value_business_receipt') {
                this.roleCodeList.forEach(item => {
                    if (item.roleCode === 'DZYWJSR') {
                        roleIds.push(item.roleId);
                    }
                });
            }
            if (roleIds.length < 1) {
                return;
            }
            return roleIds;
        },
        handleCurrentChange(val) {
            this.currentRow = val;
        },
        getDisabled(workFlowCode) {
            if (workFlowCode === 'elec_constant_value_business_start') {
                this.start = false; //开始流程
                this.audit = true; //审核流程
                this.ratify = true; //批准
                this.receipt = true; //接收
            }
            if (workFlowCode === 'elec_constant_value_business_audit') {
                this.start = true; //开始流程
                this.audit = false; //审核流程
                this.ratify = true; //批准
                this.receipt = true; //接收
            }
            if (workFlowCode === 'elec_constant_value_business_ratify') {
                this.start = true; //开始流程
                this.audit = true; //审核流程
                this.ratify = false; //批准
                this.receipt = true; //接收
            }
            if (workFlowCode === 'elec_constant_value_business_receipt') {
                this.start = true; //开始流程
                this.audit = true; //审核流程
                this.ratify = true; //批准
                this.receipt = false; //接收
            }
            if (workFlowCode === null || workFlowCode === '') {
                this.start = true; //开始流程
                this.audit = true; //审核流程
                this.ratify = true; //批准
                this.receipt = true; //接收
            }
        },
        closeForm() {
            this.$emit('closeForm');
        },
        async init(item) {
            if (item) {
                this.isDisabledForm = this.isDisabled(item);
                this.rejectNodesList = item.rejectNodes;
                this.definitionCode = item.definitionCode;
                this.data = item;
                this.instanceId = item.id;
                if (item.nextNode) {
                    this.nodeCode = item.nextNode.code;
                    this.workFlowCode = item.nextNode ? item.nextNode.code : '';
                }
                if (item.currentRecordId) {
                    let ids = [];
                    ids.push(item.currentRecordId);
                    await this.$client.getQueryRecords({ids}).then(resp => {
                        this.dataForm = resp.data.items[0].data;
                        let data = this.unitList?.filter(e => e.name === this.dataForm.jobOrder.jobUnit)[0];
                        if (data) {
                            this.jobUnitId = data.id;
                        }
                    });
                }
                
            }
            let unitType = 'DZYW';
            this.$client.queryOverhaulRoleList(unitType).then(res => {
                this.roleCodeList = res.data;
            });
            this.getRoleList();
            this.getUser();
            this.getDisabled(this.workFlowCode);
            this.visible = true;
        },
        //发起流程
        saveApply () {
            let nextRoles = [];
            let userRoleIds = this.$store.state.userInfo.roleCodeList;
            if (this.roleList) {
                //设置下环节
                userRoleIds.forEach(temp => {
                    this.roleCodeList.forEach(item => {
                        if (temp === 'DZYWFQR' && item.roleCode === 'DZYWSHR') {
                            nextRoles.push(item.roleId);
                        }
                        if (temp === 'DZYWSHR' && item.roleCode === 'DZYWPZR') {
                            nextRoles.push(item.roleId);
                        }
                        if (temp === 'DZYWPZR' && item.roleCode === 'DZYWJSR') {
                            nextRoles.push(item.roleId);
                        }
                    });
                });
            }
            if (this.workFlowCode === 'elec_constant_value_business_ratify' || this.workFlowCode === 'elec_constant_value_business_receipt') {
                this.isRatify = true;
            }
            let user = this.$store.state.userInfo;
            // if (this.workFlowCode === 'elec_constant_value_business_start') {
            //     this.dataForm.adjustBy = user.realName;
            //     this.dataForm.adjustUnit = user.orgList[0].name;
            // }
            if (this.workFlowCode === 'elec_constant_value_business_audit') {
                this.dataForm.auditor = user.realName;
            }
            if (this.workFlowCode === 'elec_constant_value_business_ratify') {
                this.dataForm.approver = user.realName;
                // this.dataForm.notificationTime = dayjs().format('YYYY-MM-DD HH:mm');
            }
            if (this.workFlowCode === 'elec_constant_value_business_receipt') {
                // this.dataForm.executeBy = user.realName;
                // this.dataForm.executableUnit = user.orgList[0].name;
            }
            //封装表单数据
            const data = Object.assign({}, this.dataForm, this.reviewForm);
            let application = {
                definitionCode: 'elec_constant_value_business',
                instanceId: this.instanceId,
                nodeCode: this.nodeCode,
                operationType: 'saveAndNext',
                data,
                user: this.$store.state.userInfo.username,
                roles: this.$store.state.userRole,
                nextRoles,
                extraProp: {
                    isRatify: this.isRatify,
                    isDelete: false,
                    billName: data.billName,
                    billNumber: data.billNumber,
                },
            }
            if(this.nodeCode==="elec_constant_value_business_start"){
                application.extraProp.createUser = this.$store.state.userInfo.username;
            }
            this.$refs.dataForm.validate(valid => {
                if (valid) {
                    this.$confirm('确认通过？', '提示', {
                        confirmButtonClass: 'el-button el-button--mini el-button--primary',
                        type: 'warning',
                    }).then(() => {
                        this.$client.workflowInstanceProcess(application).then(resp => {
                            if (resp.code === 0) {
                                this.$message({
                                    message: '操作成功',
                                    type: 'success'
                                });
                                this.closeForm(1);
                            }
                        });
                    });
                }
            });
        },
        //获取昆自运维中心人员列表
        getRoleList() {
            this.$client.getUserListByRoleId({
                current: 1,
                size: 1000,
                roleId: 15,
            }).then(data => {
                this.roleList = data.data.records;
            });
        },
        //获取用户信息
        async getUser() {
            const data = await this.$client.getUserInfo();
            let id = data.user.userId;
            let roleData = await this.$client.getUserById(id);
            this.userName = roleData.user.username;
            let res = roleData.user.sysRole;
            for (let i = 0; i < res.length; i++) {
                this.roleInfo.push(res[i].roleCode);
            }
        },
        dataExport() {
            fileDownload('/business/constantValue/dataExportInfo', {method: 'POST',
                data: this.dataForm,
            });
        },
        isDisabled(row){
            let userCode = this.$store.state.userInfo.roleCodeList;
            if (row.nextNode && userCode.indexOf('DZYWFQR') > -1 && row.nextNode.code === 'elec_constant_value_business_start') {
               return false;
            }
            if (row.nextNode && userCode.indexOf('DZYWSHR') > -1 && row.nextNode.code === 'elec_constant_value_business_audit') {
               return false;
            }
            return true;
        }


    },
};
</script>
<style>
    .factory_name:focus {
        outline: none;
    }

    .factory_name {
        margin-bottom: 4px;
        width: 240px;
        height: 30px;
        border: none;
        border-bottom: 1px solid #ffffff;
        background-color: transparent;
        font-size: 18px;
        color: #ffffff;
        text-align: center;
    }

</style>
