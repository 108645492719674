<template>
    <div style="padding: 30px 200px 30px 200px">
        <div>
            <el-form
                ref="dataForm"
                :model="dataForm"
                :rules="dataRule"
                disabled
                label-width="120px"
                label-position="top"
            >
                <el-card class="card-pub-notice card-ext-center-title">
                    <div style="text-align: center">
                        <span style="font-size: 18px">
                            <input disabled class="factory_name" v-model="dataForm.billName" />
                        </span>
                    </div>
                    <el-card>
                        <el-row :gutter="24">
                            <el-col :span="6">
                                <el-form-item label="定值单编号:">
                                    <el-input v-model="dataForm.billNumber"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="厂站名称:">
                                    <el-input v-model="dataForm.factoryName"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="设备名称:">
                                    <el-input v-model="dataForm.equipmentName"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="保护型号:">
                                    <el-input v-model="dataForm.model"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="24">
                            <el-col :span="6">
                                <el-form-item label="开关编号:">
                                    <el-input v-model="dataForm.switchNumber"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="CT变比(相):">
                                    <el-input v-model="dataForm.ctPhaseChange"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="CT变比(零):">
                                    <el-input v-model="dataForm.ctZeroChange"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="PT变比:">
                                    <el-input v-model="dataForm.ptVoltageRatio"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-table :data="dataForm.constantValueList" border style="width: 100%">
                            <el-table-column fixed type="index" label="序号" width="50"> </el-table-column>
                            <el-table-column fixed prop="name" label="定值单名称">
                                <template slot-scope="scope">
                                    <el-input v-model="scope.row.name"></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column fixed prop="oldValue" label="原定值">
                                <template slot-scope="scope">
                                    <el-input v-model="scope.row.oldValue"></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column fixed prop="newValue" label="新定值">
                                <template slot-scope="scope">
                                    <el-input v-model="scope.row.newValue"></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column fixed prop="remark" label="备注">
                                <template slot-scope="scope">
                                    <el-input v-model="scope.row.remark"></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column width="50" fixed label="操作">
                                <template slot-scope="scope">
                                    <div style="text-align: center">
                                        <i class="el-icon-remove" title="" @click="delTableData(scope.$index)"></i>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                        <div style="text-align: center">
                            <i class="el-icon-circle-plus" title=""></i>
                        </div>
                        <el-form-item label="备注:">
                            <el-input v-model="dataForm.remark" type="textarea" :rows="3"></el-input>
                        </el-form-item>
                    </el-card>
                    <el-card>
                        <el-row :gutter="24">
                            <el-col :span="6">
                                <el-form-item label="批准人">
                                    <el-input v-model="dataForm.approver"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="审核人">
                                    <el-input v-model="dataForm.auditor"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="整定单位">
                                    <el-input v-model="dataForm.adjustUnit"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="整定人">
                                    <el-input v-model="dataForm.adjustBy"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="通知时间">
                                    <el-input v-model="dataForm.notificationTime"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="执行单位">
                                    <el-input v-model="dataForm.executableUnit"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="执行人">
                                    <el-input v-model="dataForm.executeBy"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="执行时间">
                                    <el-input v-model="dataForm.executeTime"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="更改原因">
                                    <el-input v-model="dataForm.changeReason"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="执行情况">
                                    <el-input v-model="dataForm.executiveCondition"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-card>
                </el-card>
            </el-form>
        </div>
        <div style="text-align: center; margin-top: 20px">
            <el-button @click="closeForm">关闭</el-button>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            reviewForm: {
                reviewNote: '',
                reviewStatus: 0,
                reviewParam: 0,
                reviewBy: '',
            },
            currentRow: null,
            data: {},
            showRecordHistory: false,
            rejectNodesList: [],
            dialogVisible: false,
            visible: true,
            roleList: [],
            roleCodeList: [],
            tableData: [],
            roleInfo: [], //用户信息
            userName: '', //当前登录用户账号
            nodeCode: '', // 下一步流程ID
            instanceId: '', //当前流程ID
            jumpNodeId: '', //上一步流程ID
            jobUnitId: '', //运维单位id
            definitionCode: '', //流程id
            workFlowCode: '', //流程code
            start: false, //开始流程
            receipt: false, //回执流程
            audit: false, //审核流程
            isRatify: false, //是否批准
            dataForm: {
                billName: '', //定值单名称
                billNumber: '', //定值单编号
                factoryName: '', //厂站名称
                equipmentName: '', //设备名称
                model: '', //型号
                switchNumber: '', //开关编号
                ctPhaseChange: '', //CT变比（相）
                ctZeroChange: '', //CT变比(零)
                ptVoltageRatio: '', //PT变比
                constantValueList: [], //定值单
                remark: '', //备注
                approver: '', //批准人
                auditor: '', //审核人
                adjustUnit: '', //整定单位
                adjustBy: '', //整定人
                notificationTime: '', //通知时间
                executableUnit: '', //执行单位
                executeBy: '', //执行人
                executeTime: '', //执行时间
                changeReason: '', //更改原因
                executiveCondition: '', //执行情况
            },
            dataRule: {},
        };
    },
    methods: {
        closeForm() {
            this.$emit('closeForm');
        },
        async init(item) {
            if (item) {
                this.rejectNodesList = item.rejectNodes;
                this.definitionCode = item.definitionCode;
                this.data = item;
                this.instanceId = item.id;
                if (item.nextNode) {
                    this.nodeCode = item.nextNode.code;
                    this.workFlowCode = item.nextNode ? item.nextNode.code : '';
                }
                if (item.currentRecordId) {
                    let ids = [];
                    ids.push(item.currentRecordId);
                    this.$client.getQueryRecords({ids}).then((resp) => {
                        this.dataForm = resp.data.items[0].data;
                    });
                }
            }
            let unitType = 'DZYW';
            this.$client.queryOverhaulRoleList(unitType).then((res) => {
                this.roleCodeList = res.data;
            });
            this.visible = true;
        },
    },
};
</script>
<style>
.factory_name:focus {
    outline: none;
}

.factory_name {
    margin-bottom: 4px;
    width: 240px;
    height: 30px;
    border: none;
    border-bottom: 1px solid #ffffff;
    background-color: transparent;
    font-size: 18px;
    color: #ffffff;
    text-align: center;
}
</style>
